import { FC } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useAuth } from '@/context/Auth';
import { useExtraProps } from '@/pages/_app';
import useTranslation from 'next-translate/useTranslation';
import { getFullUrlByLicense } from '@/utils/multiDomains';
import Link from 'next/link';

export const FooterInternalLinks: FC<{
  baseUrl?: string;
  buttonProps?: ButtonProps;
}> = ({ baseUrl, buttonProps }) => {
  const { isLogged, userData } = useAuth();
  const { license, licensePath } = useExtraProps();
  const isSelfExcluded = userData?.status === 'self_excluded';
  const { lang, t } = useTranslation();
  const baseUrlWithLang = baseUrl || getFullUrlByLicense('MAIN', lang);

  const links: { url: string; label: string; testId: string }[] = [
    {
      url:
        isLogged && !isSelfExcluded
          ? `/me/account/responsible-gaming`
          : `${baseUrlWithLang}responsible-gaming`,
      label: t('footer:responsibleGaming'),
      testId: 'responsible-gaming-button'
    }
  ];

  if (license && license !== 'MAIN') {
    const baseUrlWithLang = baseUrl || getFullUrlByLicense(license, lang);

    // insert Term & Condition link only on domain with license: A, B or F
    links.splice(0, 0, {
      url: `${baseUrlWithLang}/terms-of-use`,
      label: t('footer:termsOfUse'),
      testId: 'terms-of-use-button'
    });

    // insert Privacy link only on domain with license: A, B or F
    links.splice(1, 0, {
      url: `${baseUrlWithLang}/privacy`,
      label: t('footer:privacyPolicy'),
      testId: 'privacy-button'
    });

    // insert Cookie Policy link only on domain with license: A, B or F
    links.splice(2, 0, {
      url: `${baseUrlWithLang}/cookies-policy`,
      label: t('footer:cookiesPolicy'),
      testId: 'cookies-policy-button'
    });
  }

  return (
    <>
      {links.map((link) => (
        <Link key={link.label} href={link.url} passHref>
          <Button
            data-testid={link.testId}
            as="a"
            fontSize="0.8rem"
            textTransform="uppercase"
            variant="link"
            {...buttonProps}
          >
            {link.label}
          </Button>
        </Link>
      ))}
    </>
  );
};
