import { Box, Stack, StackProps } from '@chakra-ui/react';
import { BrandButton } from '@/components/Button/BrandButton';
import { Link as LinkChakra } from '@chakra-ui/layout';
import { CookiesButton } from '@/components/Button/CookiesButton';
import SupportButtonModal from '@/components/Modal/SupportButtonModal';
import { FooterInternalLinks, LanguageSelector } from '@/components/Footer';
import { FC } from 'react';
import { useAppSettings } from '@/context/AppSettings';
import useTranslation from 'next-translate/useTranslation';

const linkAndButtonStyles = {
  fontSize: '0.8rem',
  fontWeight: '700',
  color: 'link !important',
  lineHeight: 'inherit !important',
  marginTop: [
    '1.25rem 0 0 0 !important',
    '1.25rem 0 0 0 !important',
    '0 !important'
  ],
  flex: ['0 0 calc(50% - .5rem)', '0 0 calc(50% - .5rem)', '0 0 auto'],
  justifyContent: 'flex-start',
  textTransform: 'uppercase',
  textAlign: 'left',
  border: 'none !important',
  wordWrap: 'break-word!important',
  hyphens: 'auto',
  _hover: {
    background: 'none !important',
    textDecoration: 'underline'
  }
};

type FooterMenuBottomProps = StackProps & {};
export const FooterMenuBottom: FC<FooterMenuBottomProps> = () => {
  const config = useAppSettings();
  const { lang, t } = useTranslation();
  return (
    <Stack backgroundColor="backgroundPrimaryDarker" width="100%">
      <Box
        maxWidth="1920px"
        marginX={['0', '0', 'auto !important']}
        width={['100%', '100%', '90%']}
        padding={['2rem 2rem 6rem 2rem', '2rem 2rem 6rem 2rem', '0.5rem 0']}
        display={['flex']}
        flexWrap="wrap"
        textAlign="left"
        alignItems={'center'}
        gap={['1rem', '1rem', '0 1rem']}
      >
        <BrandButton brandName={config.appName} />

        {config.aboutLink && config.aboutLink[lang] && (
          <LinkChakra
            href={config.aboutLink[lang]}
            isExternal
            sx={linkAndButtonStyles}
          >
            {t('footer:about')}
          </LinkChakra>
        )}

        <FooterInternalLinks buttonProps={{ sx: linkAndButtonStyles }} />
        <CookiesButton sx={linkAndButtonStyles} />

        <Box
          display={['block', 'block', 'flex']}
          flexWrap={['wrap', 'wrap', 'nowrap']}
          gap={['1rem']}
          marginLeft={[0, 0, 'auto']}
          paddingLeft={[0, 0, '1rem']}
        >
          <SupportButtonModal
            flexDirection="row"
            justifyContent="flex-start"
            textTransform="uppercase"
            variant="link"
            iconProps={{
              width: '1.5rem',
              height: '1.5rem'
            }}
            textProps={{
              fontSize: '0.8rem',
              fontWeight: '700'
            }}
          />

          <LanguageSelector
            lang={lang}
            selectProps={{
              marginTop: ['2!', '2!', '0!'],
              minWidth: ['150px']
            }}
          />
        </Box>
      </Box>
    </Stack>
  );
};
