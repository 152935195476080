import React from 'react';

interface StarrySkyProps {
  /**
   * Number of stars
   */
  starCount?: number;
  /**
   * Array of colors for the stars
   */
  starsColors?: string[];
  /**
   * Animate stars.p
   * Use with precaution as is ressource consumming!
   * You can use the percentageStarsAnimation props to animate only this percentage of stars
   */
  isStarsAnimated?: boolean;
  /**
   * Animate stars.
   * Percentage of stars you want to be animated.
   * Default: 10%
   */
  percentageStarsAnimation?: number;
  /**
   * Density of stars.
   * Possibility to make more stars inside one area.
   * Stars are over the entire surface if this props is not provided.
   *
   */
  densityStars?: 'top' | 'bottom' | 'center';
}

interface Star {
  cx: number;
  cy: number;
  coreRadius: number;
  // glowRadius: number;
  delay: number;
  duration: number;
  starColors?: string;
}

const StarrySky: React.FC<StarrySkyProps> = ({
  starCount = 200,
  starsColors = ['#fff', '#CDA660'],
  isStarsAnimated = false,
  percentageStarsAnimation = 10,
  densityStars
}) => {
  // Helper function to generate a random number between min and max
  const random = (min: number, max: number): number =>
    Math.random() * (max - min) + min;

  const percentageStars = starCount * (percentageStarsAnimation / 100);

  // Make more stars on the upper side of the screen
  const handleStarsPostion = (index: number) => {
    switch (densityStars) {
      case 'top':
        return starCount / 2 > index ? random(10, 40) : 100;
      case 'center':
        return starCount / 2 > index ? random(50, 70) : 100;
      case 'bottom':
        return starCount / 2 > index ? random(70, 100) : 100;
      default:
        return 100;
    }
  };

  // Generate an array of star objects with random properties
  const stars: Star[] = Array.from({ length: starCount }).map((_, idx) => {
    return {
      cx: random(0, 100),
      cy: random(0, handleStarsPostion(idx)),
      coreRadius: random(0.02, 0.04),
      // glowRadius: random(0.2, 0.7),
      delay: random(2, 4),
      duration: random(2, 4),
      starColors: starsColors?.[Math.round(Math.random())]
    };
  });

  const animationStars = (star: Star) => ({
    animation: `flicker ${star.duration}s infinite`,
    animationDelay: `${star.delay}s`
  });

  const handleAnimation = (index: number, star: Star) => {
    if (isStarsAnimated) {
      return index % (starCount / percentageStars) === 0
        ? animationStars(star)
        : {};
    }
    return {};
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      style={{ zIndex: -1, height: '100%', position: 'absolute' }}
    >
      <defs>
        {/* <filter id="glow-filter" x="-50%" y="-50%" width="3%" height="3%">
          <feGaussianBlur in="SourceGraphic" stdDeviation="1" />
        </filter> */}

        {/* Animation to create a flickering effect */}
        <style>
          {`
            @keyframes flicker {
              0%, 100% { opacity: 0.9}
              50% { opacity: 0.1}
            }
          `}
        </style>
      </defs>

      {stars.map((star, index) => (
        <g key={index}>
          {/* Core of the star */}
          <circle
            cx={star.cx}
            cy={star.cy}
            r={star.coreRadius}
            fill={star.starColors}
            opacity={isStarsAnimated ? random(0.4, 0.9) : random(0.5, 0.8)}
            style={handleAnimation(index, star)}
          />
          {/* Glow of the star */}
          {/* <circle
            cx={star.cx}
            cy={star.cy}
            r={star.glowRadius}
            fill={star.starColors}
            opacity={random(0.2, 0.7)}
            filter="url(#glow-filter)"
            style={handleAnimation(index, star)}
          /> */}
        </g>
      ))}
    </svg>
  );
};

export default StarrySky;
