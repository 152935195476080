import { useRouter } from 'next/router';
import { useAppSettings } from '@/context/AppSettings';
import { Divider, VStack } from '@chakra-ui/react';
import {
  FooterMenuBottom,
  FooterMenuPartners,
  FooterMenuTiles
} from '@/components/Footer';

const Footer = () => {
  const router = useRouter();
  const margin = router.pathname.includes('/me') ? '0 auto' : '0 0 auto 0';
  const config = useAppSettings();

  return (
    <VStack
      data-js-layout-part={'footer'}
      as="footer"
      position="relative"
      zIndex="base"
      margin={margin}
      width="100%"
      align="baseline"
      background={
        config.customStyle.footerBackground
          ? config.customStyle.footerBackground
          : 'transparent'
      }
      backgroundSize="cover"
      backgroundBlendMode="hard-light"
      paddingTop="4rem"
    >
      <Divider
        width="90%"
        maxWidth="1920px"
        opacity="0.3"
        background="customGrey.900"
        marginTop="0!"
        marginBottom="1rem !important"
        marginX="auto !important"
      />
      <FooterMenuTiles />
      <FooterMenuPartners />
      <FooterMenuBottom />
    </VStack>
  );
};

export default Footer;
