import { NextRouter } from 'next/router';

export interface DomainLicense {
  A?: string;
  B?: string;
  F?: string;
  MAIN: string;
}

export type DomainHUBLicense = Omit<DomainLicense, 'MAIN'>;

export type LicenceType = keyof Omit<DomainLicense, 'MAIN'>;

export const homeLicense = String(
  process.env.NEXT_PUBLIC_HOME_LICENSE || ''
).toUpperCase();

// !! do not use config because it's not allowed in nextjs middlewares
// if NEXT_PUBLIC_LICENSE_X is not set then its associated path will redirect to home
export const configDomains: DomainLicense = {
  A: process.env.NEXT_PUBLIC_LICENSE_A,
  B: process.env.NEXT_PUBLIC_LICENSE_B,
  F: process.env.NEXT_PUBLIC_LICENSE_F,
  MAIN: process.env.NEXT_PUBLIC_DOMAIN_MAIN as string
};

export const configHUBDomains: DomainHUBLicense = {
  A: process.env.NEXT_PUBLIC_HUB_LICENSE_A,
  B: process.env.NEXT_PUBLIC_HUB_LICENSE_B,
  F: process.env.NEXT_PUBLIC_HUB_LICENSE_F
};

const configPaths: DomainLicense = {
  A: '/casino',
  B: '/dice',
  F: '/sports',
  MAIN: '/'
};

const getKeyByValue = <T extends Record<string, any>>(
  obj: T,
  value: T[keyof T]
): keyof T | undefined =>
  Object.keys(obj).find((key) => obj[key] === value) as keyof T | undefined;

export function setConfigDomains(newConfig: DomainLicense) {
  // console.log('setConfigDomains', { new: newConfig, old: configDomains });
  Object.keys(configDomains).forEach(
    (key) =>
      (configDomains[key as keyof DomainLicense] =
        newConfig?.[key as keyof DomainLicense] || '')
  );
}

export function setConfigHubDomains(newConfig: DomainHUBLicense) {
  // console.log('setConfigHubDomains', { new: newConfig, old: configDomains });
  Object.keys(configHUBDomains).forEach(
    (key) =>
      (configHUBDomains[key as keyof DomainHUBLicense] =
        newConfig?.[key as keyof DomainHUBLicense] || '')
  );
}

export function getDomainByLicense(license: keyof DomainLicense): string {
  return configDomains[license] || configDomains.MAIN;
}

export function getPathByLicense(
  license: keyof DomainLicense | string,
  ignoreHomeLicense = false
): string {
  // console.log('getPathByLicense::', {
  //   ignoreHomeLicense,
  //   homeLicense,
  //   license
  // });

  if (!ignoreHomeLicense && homeLicense === license) return '';

  return (configPaths as any)[license] || configPaths.MAIN;
}

export function getFullUrlByLicense(
  license: keyof DomainLicense,
  locale: string,
  ignoreHomeLicense = false,
  useFallback = true,
  withPathname = true
): string {
  const domain = getDomainByLicense(license);

  const pathname = getPathByLicense(license, ignoreHomeLicense);
  // console.log('getFullUrlByLicense', { domain, pathname });

  if (!domain && process.env.NODE_ENV !== 'test') {
    console.log('MULTI_DOMAINS : No domain!', {
      params: { license, locale, ignoreHomeLicense, useFallback },
      domain,
      pathname
    });
    if (useFallback) return `../../../../${locale}${pathname}`;
    throw new Error('MISSING_DOMAIN');
  }
  if (withPathname) return `https://${domain}/${locale}${pathname}`;
  return `https://${domain}/${locale}`;
}

export function getLicenseByPath(
  pathname = '',
  ignoreHomeLicense = false,
  strict = false
): keyof DomainLicense {
  let license: keyof DomainLicense =
    !ignoreHomeLicense && homeLicense
      ? (homeLicense as keyof DomainLicense)
      : 'MAIN';

  if (configPaths.A && testLicensePath(pathname, configPaths.A, strict)) {
    license = 'A';
  } else if (
    configPaths.B &&
    testLicensePath(pathname, configPaths.B, strict)
  ) {
    license = 'B';
  } else if (
    configPaths.F &&
    testLicensePath(pathname, configPaths.F, strict)
  ) {
    license = 'F';
  }

  return license;
}

// if strict=true, returns true only if the next char is not a '-'
const testLicensePath = (
  pathname: string,
  licensePath: string,
  strict = false
) =>
  strict
    ? new RegExp(`^${licensePath}(?!-)`).test(pathname)
    : pathname.startsWith(licensePath);

export function isLicenseEnable(license: keyof DomainLicense): boolean {
  return !!configDomains[license];
}

export function isLicenseHUBEnable(license: keyof DomainHUBLicense): boolean {
  return !!configHUBDomains[license];
}

export function LicensesEnabled() {
  interface MyObject {
    [key: string]: string;
  }

  function getKeysWithValues(arrayOfObjects: MyObject[]): string[] {
    const result: string[] = [];
    arrayOfObjects.forEach((obj: MyObject) => {
      const key = Object.keys(obj)[0];
      const value = obj[key];
      if (value !== '') {
        result.push(key);
      }
    });
    return result;
  }

  const values = Object.keys(configHUBDomains).map((l) => ({
    [l]: configHUBDomains[l as keyof DomainHUBLicense]
  }));

  // return values;
  return getKeysWithValues(values as {}[]);
}

export function getDomainByPath(pathname: string): string {
  const license = getLicenseByPath(pathname);

  return getDomainByLicense(license);
}

export function getFullUrlByDomain(locale: string, host: string = '') {
  // const halls = process.env.NEXT_PUBLIC_HALLS_URL;
  // accept only host beginning with www
  const rgx = /^www/;

  if (rgx.test(host)) {
    // throw new Error(
    //   `Received ${host} as parameter. Must begin with "www". Protocol not required.`
    // );
    const license = getLicenseByHost(host) || 'MAIN';
    return getFullUrlByLicense(license, locale);
  }
}

export function getLicenseByHost(host: string) {
  return (
    getKeyByValue(configDomains, host) ||
    getKeyByValue(configDomains, 'www.' + host)
  );
}

export function getLicenseBySegment(segment: string | string[] | undefined) {
  const seg = Array.isArray(segment) ? segment[0] : segment || '';
  return getLicenseByPath('/' + seg); // add initial /
}

export function getSegmentByLicense(license: keyof DomainLicense | string) {
  return getPathByLicense(license, true).substring(1); // remove initial /
}

export function getFullLicenses() {
  return Object.keys(configPaths)
    .filter((x) => x !== 'MAIN')
    .map((name) => {
      return { name, path: configPaths[name as keyof DomainLicense] };
    });
}

export function getLicenseURLParam(license: LicenceType) {
  return encodeURIComponent(`${license}+`);
}

export const cleanPath = (path: string = '') => {
  const queryParamStart = path.indexOf('?');
  return queryParamStart === -1 ? path : path.slice(0, queryParamStart);
};

export const getUrlInfoFromRouter = (router: NextRouter) => {
  const cleanedPathname = cleanPath(router.asPath);
  const license = getLicenseByPath(cleanedPathname);
  const hostname = 'https://' + getDomainByLicense(license);
  const canonicalUrl = `${hostname}/${
    router?.locale ?? 'en'
  }${cleanedPathname}`;

  return {
    cleanedPathname,
    license,
    hostname,
    canonicalUrl
  };
};

export const getHubHomeLinkByLicense = ({
  license,
  appSettingsFeatures,
  locale = 'en'
}: {
  license: keyof DomainHUBLicense;
  appSettingsFeatures?: any;
  locale?: string;
}) => {
  let url = getFullUrlByLicense(license, locale, false, false, false);

  if (isLicenseHUBEnable(license)) {
    url = `https://${configHUBDomains[license]}/${locale}`;
  }

  if (appSettingsFeatures?.[`customHubLicense${license}Home`]) {
    return `${url}/${appSettingsFeatures[`customHubLicense${license}Home`]}`;
  }

  return url;
};
