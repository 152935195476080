import SupportButtonModal from '@/components/Modal/SupportButtonModal';
import { useAppSettings } from '@/context/AppSettings';
import { useAuth } from '@/context/Auth';
import { toRem } from '@/helpers/toRem';
import { useCurrentHost } from '@/hooks/useCurrentHost';
import useMediaQuery from '@/hooks/useMediaQuery';
import { AboutIcon, MenuIcon } from '@/theme/Icons';
import { getLicenseByHost } from '@/utils/multiDomains';
import {
  Box,
  Button,
  ButtonProps,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Link,
  List,
  ListItem,
  StackProps,
  useDisclosure
} from '@chakra-ui/react';
import { MotionProps } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import {
  FooterInternalLinks,
  LanguageSelector,
  PaymentMethodsIcons
} from '../Footer';
import LogoTestAccount from '../Logo/LogoTestAccount';
import ProfileLogOut from '../Profile/Profile_Logout/ProfileLogout';
import ContextualLinks, { ContextualLinksTypes } from './ContextualLinks';
import ContextualMainMenuItem from './ContextualMainMenuItem';
import { useExtraProps } from '@/pages/_app';
export type ContextualNavigationProps =
  | (StackProps & ButtonProps)
  | MotionProps;
export type MenuButtonProps = (ButtonProps | MotionProps) & {
  onOpen: () => void;
};
const MobileButton = ({ onOpen }: MenuButtonProps) => {
  return (
    <Box onClick={onOpen} aria-label="filter button" position="relative">
      <Button variant={'unstyled'}>
        <MenuIcon fontSize={'2rem'} width={34} height={34} />
      </Button>
    </Box>
  );
};

const Drawer = dynamic(() =>
  import('@chakra-ui/react').then((mod) => mod.Drawer)
);

const DesktopButton = ({ onOpen }: MenuButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      variant={'unstyled'}
      display="flex"
      alignItems={'center'}
      p={`${toRem(15)} ${toRem(24)}`}
      onClick={onOpen}
      borderRadius="2rem"
      border="1px solid rgba(255, 255, 255, 0.2)"
      textTransform="uppercase"
      fontSize={toRem(13)}
      fontWeight="600"
      letterSpacing=".2rem"
      leftIcon={
        <MenuIcon
          width={toRem(19)}
          height={toRem(19)}
          color="#fff"
          fontSize={toRem(13)}
          marginBottom="3px"
        />
      }
    >
      {t('common:menu')}
    </Button>
  );
};
const ContextualNavigation = ({
  data,
  hallsUrl,
  position
}: ContextualNavigationProps & ContextualLinksTypes) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useMediaQuery('(max-width: md)');

  const { t, lang: locale } = useTranslation();
  const { isLogged } = useAuth();
  const config = useAppSettings();
  const { license } = useExtraProps();

  const currentHost = useCurrentHost();
  // const license = getLicenseByHost(currentHost) || 'MAIN';
  const currentIdxLicense = data?.findIndex((nav) => nav.license === license);

  return (
    <>
      {isMobile ? (
        <MobileButton onOpen={onOpen} />
      ) : (
        <DesktopButton onOpen={onOpen} />
      )}

      <Drawer
        onOverlayClick={onClose}
        onEsc={onClose}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        size={['full', 'xs']}
        isFullHeight={true}
        variant="withOverlay"
      >
        <DrawerOverlay />
        <DrawerContent background={'#1c1c1c'}>
          <DrawerCloseButton size="lg" top={'1.5rem'} left={'1.5rem'} />
          <DrawerHeader
            as={HStack}
            justifyContent="center"
            marginTop={toRem(80)}
          >
            <LogoTestAccount />
          </DrawerHeader>

          <DrawerBody padding={'2rem'} flex={['none', 'none', 1]}>
            <ContextualMainMenuItem
              data={data}
              currentIdx={currentIdxLicense}
            />
            <ContextualLinks
              data={data}
              hallsUrl={hallsUrl}
              position={position}
              showMainNavItem={false}
            />
            <List
              marginLeft={position === 'row' ? toRem(31) : 0}
              display="flex"
              gap={'1rem'}
              flexDirection={position}
              marginTop={'1.5rem'}
            >
              <ListItem color={'whiteAlpha.800'}>
                <SupportButtonModal
                  display="flex"
                  flexDirection="row"
                  border="none"
                  background="none"
                  padding="0"
                  height="auto"
                  fontSize="1rem"
                  fontWeight="400"
                  color="inherit"
                  iconProps={{
                    width: '1.2rem',
                    height: '1.2rem',
                    marginRight: '0.5rem',
                    color: 'inherit'
                  }}
                  _hover={{
                    background: 'none',
                    textDecoration: 'underline'
                  }}
                />
              </ListItem>
              {config.aboutLink && config.aboutLink[locale] && (
                <ListItem>
                  <Link href={config.aboutLink[locale]} isExternal>
                    <AboutIcon
                      width={'1.2rem'}
                      height={'1.2rem'}
                      marginRight="0.5rem"
                      fontSize="1rem"
                      fontWeight="400"
                    />
                    {t('common:leftNavAboutUs')}
                  </Link>
                </ListItem>
              )}
            </List>
          </DrawerBody>
          <DrawerFooter>
            <Box borderTop={'1px solid #333'} width={'full'} pt={'3rem'}>
              <HStack justifyContent={'space-between'} marginBottom={'2.5rem'}>
                <div>{isLogged && <ProfileLogOut />}</div>
                {/* <div>social icons</div> */}
              </HStack>
              <Box display={'inline-block'}>
                <FooterInternalLinks
                  buttonProps={{
                    fontSize: '0.5rem',
                    color: 'whiteAlpha.700',
                    variant: 'link',
                    marginInlineEnd: '0.5rem'
                  }}
                />
              </Box>
              <HStack justifyContent={'space-between'} paddingTop={'1rem'}>
                <HStack flexWrap={'wrap'} flex={'1 1 0'}>
                  <PaymentMethodsIcons
                    gridProps={{
                      width: '1.5rem',
                      height: '1rem',
                      borderRadius: 'sm',
                      justifyContent: 'flex-start',
                      style: {
                        marginInlineStart: '0rem',
                        marginInlineEnd: '0rem',
                        marginRight: '.3rem',
                        marginBottom: '.3rem'
                      }
                    }}
                  />
                </HStack>
                <LanguageSelector
                  lang={locale}
                  selectProps={{
                    flex: '0 0 max-content'
                  }}
                />
              </HStack>
            </Box>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default ContextualNavigation;
