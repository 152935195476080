import { FC } from 'react';
import { getFallbackLanguage } from '@/helpers/lang';
import { getBrandComponent } from '@/helpers/getBrandComponent';
import { LegalComponentProps } from '@/helpers/legals';

export const LegalComponent: FC<LegalComponentProps> = (props) => {
  const { lang, license, path } = props;

  // if (['MAIN', 'A'].includes(license) || lang === 'default') {
  //   return null;
  // }

  const componentLang = getFallbackLanguage(lang)?.toUpperCase();
  const componentPath = `${path}${license}License${componentLang}`;

  console.log('!1 LegalComponent', { componentPath, lang, license });

  try {
    const Component = getBrandComponent(componentPath);
    console.log('!2 LegalComponent', { Component });
    return <Component />;
  } catch (e) {
    console.log('!3 LegalComponent', { e });
    return null;
  }
};
