import { Grid, GridProps, Image, Tooltip } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { PaymentMethod } from '@/components/UserSettings/Wallet/CashIn/CashInForm';

type PaymentCardProps = {
  icon: any;
  label: string;
  gridProps?: GridProps;
};

const PaymentCard = ({ icon, label, gridProps }: PaymentCardProps) => (
  <Grid
    padding="0.1rem"
    width="3rem"
    height="2rem"
    borderRadius="lg"
    backgroundColor="white"
    placeItems="center"
    overflow="hidden"
    alignItems="center"
    justifyContent="center"
    position="relative"
    style={{
      marginInlineStart: '0rem',
      marginInlineEnd: '0rem',
      marginRight: '.5rem',
      marginBottom: '.5rem'
    }}
    {...gridProps}
  >
    <Tooltip label={label}>
      {/* <Icon src width="100%" height="100%" /> */}
      <Image
        src={icon}
        alt={label}
        position="absolute"
        maxWidth="90%"
        maxHeight="90%"
      />
    </Tooltip>
  </Grid>
);

export const PaymentMethodsIcons: FC<{ gridProps?: GridProps }> = ({
  gridProps
}) => {
  const [paymentsData, setPaymentData] = useState<PaymentMethod[]>([]);

  useEffect(() => {
    const getAvailablePayments = async () => {
      const data = await fetch('/api/paymentMethods');
      const paymentsMethod = await data.json();
      setPaymentData(paymentsMethod);
    };

    void getAvailablePayments();
  }, []);

  return (
    <>
      {paymentsData.map((card, index) => (
        <PaymentCard
          key={index}
          icon={card.icon}
          label={card.method}
          gridProps={gridProps}
        />
      ))}
    </>
  );
};
