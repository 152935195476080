import {
  Button,
  ButtonProps,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Link,
  LinkProps
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { PropsWithChildren } from 'react';

export type GenericLegalModalWithCtaType =
  | GenericLegalModalWithButtonType
  | GenericLegalModalWithLinkType;

type GenericLegalModalWithButtonType = PropsWithChildren &
  ButtonProps & {
    label: string;
    ctaType: 'button';
  };

type GenericLegalModalWithLinkType = PropsWithChildren &
  LinkProps & {
    label: string;
    ctaType: 'link';
  };

const GenericLegalModalWithCta = ({
  label,
  children,
  ctaType,
  ...props
}: GenericLegalModalWithCtaType) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation();

  return (
    <>
      {ctaType === 'button' ? (
        <Button onClick={onOpen} variant="link" {...(props as ButtonProps)}>
          {t(`${label}`)}
        </Button>
      ) : null}
      {ctaType === 'link' ? (
        <Link
          tabIndex={0}
          onClick={onOpen}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault(); // Prevents spacebar from scrolling the page
              onOpen();
            }
          }}
          {...(props as LinkProps)}
        >
          {t(`${label}`)}
        </Link>
      ) : null}
      <Modal size="full" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton position="sticky" />
          {children}
        </ModalContent>
      </Modal>
    </>
  );
};

export default GenericLegalModalWithCta;
