import { useAppSettings } from '@/context/AppSettings';
import { HStack } from '@chakra-ui/react';
import { useScroll, useTransform } from 'framer-motion';
import { RefObject } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import BackButton from '../Button/BackButton';
import { Search } from '../GamesCatalog';
import Filters from '../GamesCatalog/Filters';
import LogoTestAccount from '../Logo/LogoTestAccount';
import { MotionVStack } from '../Motion';
import { CatalogTinyInfo } from '../Profile/Profile_TinyInfo/CatalogTinyInfo';
import CustomHeader from './CustomHeader';
import ContextualNavigation from './ContextualNavigation';
import ContextualLinks, {
  ContextualLinksTypes,
  ContextualNavItem
} from './ContextualLinks';
import { useCurrentHost } from '@/hooks/useCurrentHost';
import { getLicenseByPath, LicensesEnabled } from '@/utils/multiDomains';
import { useAuth } from '@/context/Auth';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

type NavbarProps = {
  observeRef?: RefObject<HTMLDivElement>;
  basic?: boolean;
  backButton?: boolean;
  showLoginButton?: boolean;
  showRegisterButton?: boolean;
  showNav?: boolean;
  showAllUsersActions?: boolean;
};

const scrollYRange = [0, 300];

const { publicRuntimeConfig } = getConfig() || {};
const isNewsEnabled = publicRuntimeConfig.currentAppConfig.isNewsEnabled;

const NavBar = ({
  basic = false,
  backButton = false,
  showLoginButton = true,
  showRegisterButton = true,
  showNav = true,
  showAllUsersActions = true
}: NavbarProps) => {
  const { asPath } = useRouter();
  const isHub = getLicenseByPath(asPath) === 'MAIN';
  const isMobile = useMediaQuery('(max-width: md)');
  const { scrollY: scrollYViewport } = useScroll();
  const appSettings = useAppSettings();

  const hasSmallHeaderScroll =
    !appSettings.features.components.OriaCasinoIcon?.disable;

  const yPosition =
    appSettings.appName === 'oria'
      ? 'translate3D(-50%, -2.5rem, 0)'
      : 'translate3D(-50%, -1.7rem, 0)';
  const logoPosition = useTransform(scrollYViewport, scrollYRange, [
    'translate3D(-50%, 1.5rem, 0)',
    yPosition
  ]);

  const firstOpacity = useTransform(scrollYViewport, [0, 200, 300], [1, 0, 0]);
  const opacity = useTransform(scrollYViewport, [0, 200, 300], [0, 0, 1]);
  const currentHost = useCurrentHost();
  const { userData } = useAuth();
  const isBanned = userData?.status === 'banned';
  const selfExcluded = userData?.status === 'self_excluded';

  const currentNav = appSettings.features
    .contextualNavigation as ContextualNavItem[];

  const enabledLicenses = LicensesEnabled();

  let filteredData = currentNav?.filter((nav) => {
    return enabledLicenses.includes(nav.license);
  });

  // keep only active menu items
  filteredData = filteredData?.map((navParent: ContextualNavItem) => {
    navParent.itemMenu = navParent.itemMenu.filter((navItem) => navItem.active);
    return navParent;
  });

  const navData: ContextualLinksTypes = {
    data: filteredData,
    hallsUrl: appSettings?.hallsUrl
  };

  const isMainDomain =
    appSettings.isHubEnabled && currentHost === appSettings.domains.MAIN;

  // Only used when header hasn't a small logo
  let logoMaxHeight = appSettings.customStyle?.logoHeaderMaxHeight;
  logoMaxHeight = useTransform(scrollYViewport, scrollYRange, [
    logoMaxHeight?.initial || 0,
    logoMaxHeight?.onScroll || 0
  ]);

  return (
    <CustomHeader>
      <HStack justifyContent={basic ? 'space-between' : 'flex-end'}>
        {showNav ? (
          <>
            <ContextualNavigation {...navData} position="column" />
            {!isMobile && appSettings.isTopNavigationEnabled === 'true' ? (
              <ContextualLinks {...navData} />
            ) : null}
          </>
        ) : null}

        {basic && backButton ? <BackButton /> : null}
      </HStack>
      <MotionVStack
        spacing="0"
        style={{
          transform: hasSmallHeaderScroll ? logoPosition : 'translateX(-50%)',
          left: '50%',
          position: 'absolute',
          maxHeight: hasSmallHeaderScroll ? 'initial' : logoMaxHeight
        }}
      >
        <LogoTestAccount
          style={{
            opacity: hasSmallHeaderScroll ? firstOpacity : 1
          }}
        />
        {hasSmallHeaderScroll && <LogoTestAccount small style={{ opacity }} />}
      </MotionVStack>

      <HStack>
        {!basic && !isMainDomain && (
          <WithSearchButton predicat={isBanned || selfExcluded} />
        )}
        {!basic && (
          <>{isMobile && !isMainDomain && !selfExcluded ? <Filters /> : null}</>
        )}
        {!isMobile && !selfExcluded ? (
          <CatalogTinyInfo
            showRegisterButton={isHub ? false : showRegisterButton}
            showLoginButton={isHub ? false : showLoginButton}
            showAllUsersActions={isHub ? false : showAllUsersActions}
          />
        ) : null}
        )
      </HStack>
    </CustomHeader>
  );
};
NavBar.getInitialProps = async () => {
  return {};
};
export default NavBar;

const WithSearchButton = ({ predicat }: { predicat: Boolean }) => {
  return !predicat ? <Search /> : null;
};
