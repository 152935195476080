import { HTTP } from '@/components';
import { getFallbackLanguage } from '@/helpers/lang';
import setLanguage from 'next-translate/setLanguage';
import { FC } from 'react';
import { Select, SelectProps } from '@chakra-ui/react';
import { useAppSettings } from '@/context/AppSettings';

const changeLanguage = async (e: React.ChangeEvent<HTMLSelectElement>) => {
  HTTP.defaults.headers.common['Accept-Language'] = getFallbackLanguage(
    e.target.value as string
  );

  // setLocale() doesn't work, so we destroy and reinitialize inside component
  // window.fcWidget?.user.setLocale(e.target.value);
  window.fcWidget?.destroy();

  await setLanguage(e.target.value);
  document.cookie = `NEXT_LOCALE=${e.target.value}; max-age=31536000; path=/`;
};

export const LanguageSelector: FC<{
  lang: string;
  selectProps?: SelectProps;
}> = ({ lang, selectProps }) => {
  const config = useAppSettings();
  const availableLanguagesObject: Record<string, string> =
    config.availableLanguagesObject;
  return (
    <Select
      data-testid="change-language-select"
      flex="1"
      onChange={changeLanguage}
      border="none"
      value={lang}
      fontWeight="bold"
      bg="backgroundPrimaryDarker"
      {...selectProps}
    >
      {Object.entries(availableLanguagesObject).map(([locale, label]) => {
        return (
          <option key={`locale-${locale}`} value={locale}>
            {label}
          </option>
        );
      })}
    </Select>
  );
};
