import { useAuth } from '@/context/Auth';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, { FC, useEffect, useState } from 'react';
import { BackToRealityPropsType } from './BackToReality.types';
import Trans from 'next-translate/Trans';
import TermOfUseModalWithCta from '@/components/Modal/TermsOfUseModalWithCta';
import { HTTP } from '@/components';
import { APIUserType } from '@/context/Auth.types';
import PrivacyPolicyModalWithCta from '@/components/Modal/PrivacyPolicyModalWithCta';

export const localKey = 'BackToReality'; // key in local storage

const BackToReality: FC<BackToRealityPropsType> = ({
  delayInMS = 4 * 60 * 60 * 1000,
  delayInText = '4h' // used in modal title
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  // const [localValue, setLocalValue] = useLocalStorage(localKey, 0); // timestamp in milliseconds
  const [localValue, setLocalValue] = useState<number>(0);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [needConsent, setNeedConsent] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isLogged, userData, setUserData } = useAuth();

  const [consentGiven, setConsentGiven] = useState(false);

  useEffect(() => {
    if (userData) {
      if (!userData.optInTermsAndConditions) {
        setModalVisible(true);
      }
      setNeedConsent(!userData.optInTermsAndConditions);
    }
  }, [userData]);

  // display modal + sync timer with localStorage
  useEffect(() => {
    if (!isLoaded || modalVisible) return;

    const curTime = new Date().getTime();
    if (isLogged && timeoutId && localValue + delayInMS > curTime) return;

    if (isLogged) {
      // testing new way
      const storedValue = window?.localStorage.getItem(localKey);
      const newLocalValue = parseInt(storedValue || '0');

      const diffTime = Math.max(
        0,
        Math.abs(newLocalValue || curTime) + delayInMS - curTime
      ); // using max in case of negative result

      // clear previous timer
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // start new timer
      const timeoutDelay =
        diffTime > 0
          ? Math.min(delayInMS, diffTime)
          : newLocalValue === 1
          ? 0
          : delayInMS;

      setTimeoutId(
        setTimeout(
          () => {
            setModalVisible(true);
          },
          timeoutDelay
          // newLocalValue + delayInMS - curTime
        )
      );

      // save in local storage
      const isNewLogin = newLocalValue == 1;
      const isNewLoginClosed = isNewLogin && !modalVisible;
      const isPast = !isNewLogin && newLocalValue + delayInMS <= curTime;
      const newValue = isNewLoginClosed || isPast ? curTime : newLocalValue;

      window?.localStorage.setItem(localKey, newValue + '');
      setLocalValue(newValue);
    } else if (timeoutId) {
      // clear timer
      clearTimeout(timeoutId);
      setTimeoutId(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, isLogged, localValue, modalVisible]);

  useEffect(() => {
    setIsLoaded(true);
    return () => timeoutId && clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onModalClose = async () => {
    if (!needConsent) {
      setModalVisible(false);
      // const curTime = new Date().getTime();
      // if (localValue + delayInMS < curTime) setLocalValue(curTime);
    }

    if (needConsent && consentGiven) {
      try {
        setIsLoading(true);
        const { data } = await HTTP.post<APIUserType>(
          `user/${userData?.id}/consent-terms-and-conditions`,
          {}
        );

        if (!data.optInTermsAndConditions) {
          throw new Error("Terms haven't been updated");
        }

        setUserData(data);
        setModalVisible(false);
      } catch {
        toast({
          status: 'error',
          position: 'top-right',
          title: t('common:somethingWrongHappenned')
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div data-testid="backToReality">
      <Modal
        closeOnOverlayClick={false}
        isOpen={modalVisible}
        onClose={onModalClose}
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader data-testid="backToReality-ModalHeader">
            {t('common:hello')}, {userData?.username ?? 'Dear player'}
          </ModalHeader>
          <ModalBody data-testid="backToReality-ModalBody">
            {needConsent ? (
              <>
                <Box mb={'1rem'} data-testid="updated-terms-body">
                  <Trans
                    i18nKey={'common:termsHaveBeenUpdated'}
                    components={{
                      termsOfUse: (
                        <TermOfUseModalWithCta
                          data-testid="see-terms-of-use"
                          fontWeight={'bold'}
                          color={'brand.1000'}
                          ctaType={'link'}
                        />
                      ),
                      privacyPolicy: (
                        <PrivacyPolicyModalWithCta
                          data-testid="see-privacy-policy"
                          fontWeight={'bold'}
                          color={'brand.1000'}
                          ctaType={'link'}
                        />
                      )
                    }}
                  />
                </Box>
                <Checkbox
                  data-testid="accept-terms"
                  mb={'1rem'}
                  colorScheme="brand"
                  isChecked={consentGiven}
                  onChange={() => setConsentGiven((prevState) => !prevState)}
                >
                  <b>{t('common:acceptTerms')}</b>
                </Checkbox>
                <Divider mb={'1rem'} />
              </>
            ) : null}
            <b>{t('common:rememberResponsible')}</b>
            <br />
            {t('common:safeTravel')}
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button
              data-testid="backToReality-ModalClose"
              backgroundColor="buttonPrimary"
              colorScheme="blackAlpha"
              onClick={onModalClose}
              isDisabled={isLoading || (needConsent && !consentGiven)}
              isLoading={isLoading}
            >
              {t('common:gotIt')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default BackToReality;
