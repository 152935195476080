import { Button, ButtonProps, Text } from '@chakra-ui/react';
import { FC } from 'react';

type BrandButtonProps = ButtonProps & {
  brandName: string;
};
export const BrandButton: FC<BrandButtonProps> = ({ brandName, ...props }) => (
  <Button
    fontSize="1rem"
    fontWeight="700"
    variant="link"
    cursor="initial"
    width={['100%', '100%', 'initial']}
    marginBottom={['0.5rem', '0.5rem', '0']}
    justifyContent="flex-start"
    _hover={{
      textDecoration: 'none'
    }}
    {...props}
  >
    <Text as="span">&#9400;&nbsp;</Text>
    {brandName.charAt(0).toUpperCase() + brandName.slice(1)}{' '}
    {new Date().getFullYear()}
  </Button>
);
