import ActionRequired from '@/components/ActionRequired';
import { HTTP } from '@/components/Http';
import RealStepper from '@/components/Stepper';
import SelectAmount from '@/components/UserSettings/Wallet/CashOut/steps/SelectAmount';
import { useAuth } from '@/context/Auth';
import { useCashOutConfig } from '@/context/CashoutConfig';
import { BankIcon, PaypalIcon } from '@/theme/Icons';
import { Spinner, useToast } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useMemo, useState } from 'react';
import CashOutConfig, {
  CashOutConfigType,
  CashOutPayoutMethods
} from './CashOutConfig';
import WalletPaypalCashOut from './Paypal';
import Success from './steps/Success';

const WalletCashOut = () => {
  const { setPayoutCfg, setConfigCashout, configCashout } = useCashOutConfig();
  const { t } = useTranslation();
  const { userData } = useAuth();
  const [isConfigLoading, setIsConfigLoading] = useState<boolean>(true);
  const toast = useToast();

  const memoCashoutCfg = useMemo(() => {
    return [
      {
        key: 'bank_transfer',
        component: (
          <ActionRequired isRequired={{ bankAccount: true }}>
            <SelectAmount />
          </ActionRequired>
        ),
        header: {
          icon: <BankIcon />,
          hasIconBackground: false,
          methodPayment: t('wallet:bank_transfer'),
          introTxt: t('wallet:cashoutUnavailableBankingTransfer'),
          extraInfos: [
            {
              [t('wallet:cashoutExtraInfosTitleFees')]: t(
                'wallet:cashoutExtraInfosValueFeesBankTransfer'
              )
            },
            {
              [t('wallet:cashoutExtraInfosTitleDelay')]: t(
                'wallet:cashoutExtraInfosValueDelayBankTransfer'
              )
            }
          ]
        },
        isAvailable: true
      },
      {
        key: 'paypal',
        component: <WalletPaypalCashOut />,
        header: {
          icon: <PaypalIcon />,
          hasIconBackground: true,
          methodPayment: 'Paypal',
          introTxt: t('wallet:cashoutUnavailablePaypal'),
          extraInfos: [
            {
              [t('wallet:cashoutExtraInfosTitleFees')]: t(
                'wallet:cashoutExtraInfosValueFeesBankPaypal'
              )
            },
            {
              [t('wallet:cashoutExtraInfosTitleDelay')]: t(
                'wallet:cashoutExtraInfosValueDelayBankPaypal'
              )
            }
          ]
        },
        isAvailable: true
      }
      // {
      //   key: 'skrill',
      //   header: {
      //     icon: <Image src="/payment-methods/skrill-wallet.svg" />,
      //     hasIconBackground: true,
      //     methodPayment: 'Skrill',
      //     introTxt: t('wallet:cashoutUnavailableSkrill')
      //   },
      //   isAvailable: false
      // }
    ];
  }, []);

  const handlePayoutMethods = async () => {
    try {
      setIsConfigLoading(true);
      const result = await HTTP.get(`wallet/user/me/payout_methods`);
      setIsConfigLoading(false);
      const data: CashOutPayoutMethods[] = result?.data;
      setPayoutCfg(data);

      // merge payout_methods to the initial config
      const merged = memoCashoutCfg
        .map((config) => {
          for (const payout of data) {
            if (config.key === payout.name) {
              return {
                ...config,
                isAvailable: payout.provider.isAllowed
              };
            }
          }
        })
        .filter((config) => config !== undefined);
      setConfigCashout(merged as CashOutConfigType[]);
    } catch (error) {
      console.error(
        `error while loading wallet/user/me/payout_methods: ${error}`
      );
      toast({
        status: 'error',
        position: 'top-right',
        title: t('common:somethingWrongHappenned')
      });
      setIsConfigLoading(false);
    }
  };

  useEffect(() => {
    if (userData?.id) handlePayoutMethods();
  }, [userData?.id]);

  return (
    <>
      {isConfigLoading ? (
        <Spinner />
      ) : (
        <RealStepper>
          <CashOutConfig config={configCashout} />
          <Success />
        </RealStepper>
      )}
    </>
  );
};

export default WalletCashOut;
