import BorderedBox from '@/components/Box';
import { HTTP } from '@/components/Http';
import { useStepper } from '@/components/Stepper';
import { useSettings } from '@/components/UserSettings/SettingsContext';
import { useAuth } from '@/context/Auth';
import { useCashOutConfig } from '@/context/CashoutConfig';
import { useWallet } from '@/context/Wallet';
import { humanCurrency } from '@/helpers/humanCurrency';
import { CurrencyBase } from '@/hooks/useFormat';
import { EuroIcon, InformationIcon } from '@/theme/Icons';
import {
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  VStack,
  useToast
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, {
  ChangeEvent,
  LegacyRef,
  createRef,
  useState,
  useEffect
} from 'react';
import { toRem } from '@/helpers/toRem';

const CashOutPaypal = () => {
  const { t } = useTranslation();
  const { balance, getBalance } = useWallet();
  const { userData } = useAuth();
  const { next } = useStepper();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isError, setIsError] = useState<string>('');
  const [paypalEmail, setPaypalEmail] = useState<string | null>(null);
  const toast = useToast();
  const { setSliderValue, minCashOut } = useSettings();

  const inputNumberRef: LegacyRef<HTMLInputElement> | undefined = createRef();
  const { payoutMethods, setMethodType } = useCashOutConfig();

  const paypalInfos = payoutMethods?.filter(
    (method) => method.name === 'paypal'
  )?.[0];

  const minAmountProvider = paypalInfos?.provider.minimumAmount;
  const maxAmountProvider = paypalInfos?.provider.maximumAmount;

  const [amount, setAmount] = useState<string>(
    (minCashOut / 100).toString().replace('.', ',')
  );

  const checkValue = () => {
    if (+amount > maxAmountProvider / 100) {
      toast({
        title: `${t('wallet:maximumCashOutExceeded')} : ${humanCurrency(
          maxAmountProvider,
          'fr',
          'EUR',
          CurrencyBase.EURO
        )}`,
        status: 'warning',
        position: 'top',
        duration: 4000,
        isClosable: true
      });
      setAmount(maxAmountProvider / 100 + '');
      setIsError(
        `${t('wallet:maximumCashOutExceeded')} : ${humanCurrency(
          maxAmountProvider,
          'fr',
          'EUR',
          CurrencyBase.EURO
        )}`
      );
    } else if (+amount < minAmountProvider / 100) {
      toast({
        title: `${t('wallet:minimumCashOutRequired', {
          amount: humanCurrency(
            minAmountProvider,
            'fr',
            'EUR',
            CurrencyBase.EURO
          )
        })}`,
        status: 'warning',
        position: 'top',
        duration: 4000,
        isClosable: true
      });
      setAmount(minAmountProvider / 100 + '');
      setIsError(
        `${t('wallet:minimumCashOutRequired', {
          amount: humanCurrency(
            minAmountProvider,
            'fr',
            'EUR',
            CurrencyBase.EURO
          )
        })}`
      );
    } else {
      setIsError('');
    }
  };

  const requestPayload = () => ({
    // Replace commas with dots for internal consistency
    amount: +amount.replace(/,/g, '.') * 100,
    currency: 'EUR',
    origin: 'website',
    playerId: userData?.id
  });

  const onSubmit = async () => {
    const requestCashOut = requestPayload();
    try {
      setIsSubmitting(true);
      setSliderValue?.(amount);
      const result = await HTTP.post('wallet/paypal_transfers', requestCashOut);
      if (result.status === 201) {
        setMethodType('paypal');
        setIsSubmitting(false);
        toast({
          title: t('common:success'),
          description: t('wallet:cashOutRegistered'),
          status: 'success',
          position: 'top',
          duration: 4000,
          isClosable: true
        });
        getBalance?.();
        next();
      } else {
        throw new Error();
      }
    } catch (error: any) {
      setIsSubmitting(false);
      console.error(`error while POST to wallet/paypal_transfers: ${error}`);
    }
  };

  const filterInput = (input: string): string => {
    // Allow only numbers, dots, and commas
    const validChars = /[^0-9.,]/g;
    // Remove invalid characters
    return input.replace(validChars, '');
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;
    // Filter the input
    const filteredValue = filterInput(inputValue);
    setAmount(filteredValue);
  };

  useEffect(() => {
    const loadUserAssociation = async () => {
      try {
        const userAssocation = await HTTP.get(`/paypal/email`);
        setPaypalEmail(userAssocation.data);
      } catch (err) {
        setPaypalEmail(null);
      }
    };
    loadUserAssociation();
  }, []);

  return (
    <>
      {paypalEmail ? (
        <Text
          pb={'2rem'}
          color={'rgba(255, 255, 255, 0.6)'}
          fontSize={toRem(12)}
        >
          Account: {paypalEmail}
        </Text>
      ) : null}
      <Text fontSize="1rem" alignSelf="flex-start" fontWeight="500" mt=".5rem">
        {t('wallet:cashoutAvailableBalanceBankTransfer')}{' '}
        {humanCurrency(
          balance?.amount ? balance?.amount : 0,
          'fr',
          'EUR',
          CurrencyBase.EURO
        )}
      </Text>
      <BorderedBox
        spacing="1rem"
        margin=".5rem 0 2rem 0 !important"
        border="none"
        padding={0}
        borderRadius={0}
        background="none"
      >
        <HStack
          alignSelf={'flex-end'}
          flexDirection={['column', 'row']}
          width={'100%'}
        >
          <InputGroup w={'full'}>
            <InputLeftElement
              onClick={() => inputNumberRef.current?.focus()}
              height="100%"
            ></InputLeftElement>
            <Input
              data-testid="selected-amount-input"
              borderColor={'figma.neutral.300'}
              inputMode={'decimal'}
              value={amount}
              onChange={handleChangeInput}
              onBlur={checkValue}
              textAlign={'start'}
              fontSize="1.5rem"
              fontWeight="bold"
              borderRadius=".5rem"
              padding="1rem"
              paddingRight={'1rem'}
              sx={{ textIndent: '0rem' }}
              ref={inputNumberRef}
              isInvalid={!!isError}
            />
            <InputRightElement
              height="100%"
              width="1.5rem"
              fontSize="1.5rem"
              right="1rem"
            >
              {!!isError ? <InformationIcon /> : <EuroIcon />}
            </InputRightElement>
          </InputGroup>
        </HStack>
        {!!isError ? <Text color="#FC8181">{isError}</Text> : null}

        <VStack width="100%" alignItems="flex-start">
          <Text fontSize="1rem">
            <b>Min.</b>{' '}
            {humanCurrency(
              paypalInfos?.provider.minimumAmount,
              'fr',
              'EUR',
              CurrencyBase.EURO
            )}
          </Text>
          <Text fontSize="1rem">
            <b>Max.</b>{' '}
            {humanCurrency(
              paypalInfos?.provider.maximumAmount,
              'fr',
              'EUR',
              CurrencyBase.EURO
            )}
          </Text>
        </VStack>
      </BorderedBox>
      <HStack justifyContent="flex-end">
        <Button
          data-testid="send-cash-out-paypal"
          alignSelf={['center', 'center', 'flex-end']}
          backgroundColor="buttonPrimary"
          size="md"
          onClick={onSubmit}
          isDisabled={isSubmitting}
          marginTop="2rem !important"
          padding={'0 1rem'}
        >
          {t('wallet:withdrawal')}
        </Button>
      </HStack>
    </>
  );
};
export default CashOutPaypal;
