import { Grid, GridItem, GridProps, Img } from '@chakra-ui/react';
import { FC } from 'react';
import { useAppSettings } from '@/context/AppSettings';
import useTranslation from 'next-translate/useTranslation';

type FooterMenuPartnersProps = GridProps & {};
export const FooterMenuPartners: FC<FooterMenuPartnersProps> = () => {
  const config = useAppSettings();
  const { lang } = useTranslation();
  return config.features?.clubPartners?.[lang] ? (
    <Grid
      gridTemplateColumns={'repeat(6, 1fr)'}
      padding={['0 5% 2.8rem 5%', '0 5% 2.8rem 5%', '0 3rem 4rem 3rem']}
      gridRowGap="3rem"
      marginX={['0', 'auto !important', 'auto !important']}
      width={['100%', '90%', '90%']}
      maxWidth="1920px"
      gap={6}
      justifyItems="center"
    >
      {config.features.clubPartners[lang].map((partner: any) => {
        return (
          <GridItem
            key={partner.label}
            alignItems="center"
            maxWidth="3rem"
            display="flex"
          >
            <a href={partner.url} target="_blank" rel="noreferrer">
              <Img
                src={partner.imagePath}
                maxWidth="100%"
                title={partner.label}
                alt={partner.label}
                loading="lazy"
              />
            </a>
          </GridItem>
        );
      })}
    </Grid>
  ) : null;
};
