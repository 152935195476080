import { Button, ButtonProps } from '@chakra-ui/react';

type CookiesButton = ButtonProps;
export const CookiesButton = ({ ...props }) => (
  <Button
    id="ot-sdk-btn"
    data-testid="cookies-button"
    as="a"
    variant="link"
    className="ot-sdk-show-settings"
    alignSelf={'flex-start'}
    padding={['.8em 0!important']}
    {...props}
  >
    Cookies
  </Button>
);
