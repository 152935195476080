import { ButtonProps, LinkProps } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, { useEffect, useState } from 'react';
import GenericLegalModalWithCta from './GenericLegalModalWithCta';
import { LegalComponent } from '@/components/Legals';
import { useExtraProps } from '@/pages/_app';

type TermOfUseModalWithCtaType = LinkProps &
  ButtonProps & { ctaType?: 'button' | 'link' };

const TermOfUseModalWithCta = ({
  children,
  ctaType = 'button',
  ...props
}: TermOfUseModalWithCtaType) => {
  const { lang } = useTranslation();
  const { license } = useExtraProps();
  const [label, setLabel] = useState<string>('footer:termsOfUse');

  useEffect(() => {
    if (typeof children === 'string') {
      setLabel(children);
    }
  }, [children]);

  return (
    <GenericLegalModalWithCta label={label} ctaType={ctaType} {...props}>
      <LegalComponent
        path="GeneralConditions/GeneralConditions"
        lang={lang}
        license={license}
      />
    </GenericLegalModalWithCta>
  );
};

export default TermOfUseModalWithCta;
