import { useAuth } from '@/context/Auth';
import useTranslation from 'next-translate/useTranslation';
import getConfig from 'next/config';
import Script from 'next/script';
import { createContext, useCallback, useContext, useEffect } from 'react';

export const SmarticoContext = createContext<{
  setSmarticoInitialized: (b: boolean) => void;
  isSmarticoInitialized: boolean;
}>({
  setSmarticoInitialized: (b: boolean) => undefined,
  isSmarticoInitialized: false
});

const SmarticoScript = ({ extraOnload }: { extraOnload?: () => void }) => {
  const { userData } = useAuth();
  const { lang } = useTranslation();
  const { publicRuntimeConfig } = getConfig();
  const { setSmarticoInitialized } = useContext(SmarticoContext);

  const smarticoConfig =
    publicRuntimeConfig?.currentAppConfig?.features?.smartico || {};

  const smarticoInitialize = useCallback(() => {
    window._smartico?.clear();
    window._smartico_user_id = userData?.id ?? null;
    window._smartico_language = lang ?? null;

    window._smartico?.init(smarticoConfig.label_key, {
      brand_key: smarticoConfig.brand_key
    });

    extraOnload?.();
  }, [
    smarticoConfig.label_key,
    smarticoConfig.brand_key,
    extraOnload,
    lang,
    userData
  ]);

  // ensure to have the window?._smartico object before tempting the initialization
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window?._smartico) {
        clearInterval(intervalId);
        smarticoInitialize();
        // used with CMSPage/SmarticoContext to check if smartico is initialized
        setSmarticoInitialized(true);
      }
    }, 100);
    return () => clearInterval(intervalId);
  }, [smarticoInitialize, setSmarticoInitialized]);

  return (
    <Script
      src={smarticoConfig.js}
      strategy={'lazyOnload'}
      onLoad={() => {
        smarticoInitialize();
      }}
    />
  );
};

export default SmarticoScript;

declare global {
  interface Window {
    _smartico?: {
      init(label_key: string, options: { brand_key: string }): void;
      miniGame(id: number, options?: any): void;
      dp(param: string): void;
      clear(): void;
    };
    _smartico_user_id: string | null;
    _smartico_language: string | null;
  }
}
