import { useFilters } from '@/context/Filters';
import WithFallbackComponent from '@/hoc/WithFallbackComponent';
import { HStack, StackProps, Text, VStack } from '@chakra-ui/react';
import { MotionProps } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import { CSSProperties, useRef } from 'react';
import {
  GameImage,
  GameSubTitle,
  GameTitle,
  ScrollWithoutScrollBar,
  TextWithIcon
} from '.';
import { DoubleCircleIcon, EyeIcon, VitalIcon } from '../../theme/Icons';
import NoGameFallback from '../Fallback/NoGameFallback';
import {
  MotionHStack,
  MotionVStack,
  rightToLeftChildrenElementAnimation
} from '../Motion';
import GameImageAndTitle from './GameImageAndTitle';
import SectionDescription from './SectionDescription';
import { Game, Provider } from './types';
import { DomainLicense, getFullUrlByLicense } from '@/utils/multiDomains';
import { GameCatalogItem } from '@/services/getCatalog';

type SuggestedGameProps = MotionProps &
  StackProps & {
    game: GameCatalogItem;
    providerName?: string;
    section?: string;
    tournament?: string;
    fullWidth?: boolean;
    isFavorites?: boolean;
    customStyle?: CSSProperties;
  };

export const SuggestedGame = ({
  fullWidth = false,
  game,
  providerName,
  section,
  tournament,
  isFavorites = false,
  customStyle,
  ...props
}: SuggestedGameProps) => {
  return (
    <GameImageAndTitle
      section={section}
      tournament={tournament}
      game={game}
      providerName={providerName}
      // fullWidth={fullWidth}
      // isFavorites
      style={customStyle}
      alignItems="flex-start"
      {...props}
    />
  );
};

type SuggestedGameMobileProps = MotionProps &
  StackProps & {
    game: Game;
    providers?: { [key: string]: Provider };
  };

export const SuggestedGameMobile = ({
  game,
  providers,
  ...props
}: SuggestedGameMobileProps) => {
  if (!providers) return null;
  return (
    <MotionVStack align="baseline" key={game.publicId} width="100%" {...props}>
      <HStack
        fontSize="sm"
        align="flex-end"
        width="100%"
        color="rgba(255,255,255,0.6)"
      >
        <GameImage
          src={game?.mediaUrls?.banner_desktop}
          data-testid={`suggested-mobile-${game?.name}`}
          stackProps={{
            height: '3rem',
            width: '3rem',
            minWidth: '3rem'
          }}
          borderRadius="0.5rem"
        />
        <VStack align="baseline" spacing="0" flex="1">
          <GameSubTitle fontSize="0.7rem">
            {providers?.[game.provider as string]?.name ?? ''}
          </GameSubTitle>
          <GameTitle fontSize="1rem">{game.name}</GameTitle>
        </VStack>
        <TextWithIcon spacing={0} icon={EyeIcon} text={game?.launchCount} />
        <TextWithIcon spacing={0} icon={VitalIcon} text={game?.volatility} />
      </HStack>
      <GameImage
        href={`/games/${game.id}/${game.slug}`}
        data-testid={`suggested-banner-${game?.name}`}
        src={game?.mediaUrls?.banner_desktop}
        stackProps={{
          height: '15rem',
          width: '100%'
        }}
      />
    </MotionVStack>
  );
};

type SuggestedProps = StackProps & {
  title?: string;
  games?: Game[];
  icon?: any;
};

const Suggested = ({
  games,
  title,
  icon = DoubleCircleIcon,
  ...props
}: SuggestedProps) => {
  const { filteredGames } = useFilters();
  const { t, lang: locale } = useTranslation();
  const ref = useRef(null);

  const dataToWorkWith = games ? games : filteredGames;
  const data = dataToWorkWith
    .filter((game) => game.topHit)
    // ?.sort(
    //   (a: any, b: any) =>
    //     (a.sections['forYou'] !== null ? a.sections['forYou'] : Infinity) -
    //     (b.sections['forYou'] !== null ? b.sections['forYou'] : Infinity)
    // )
    ?.slice(0, 15);

  const license = data[0].license.slice(0, 1);
  return (
    <SectionDescription
      icon={icon}
      title={title || t('catalog:suggested')}
      seeAllRoute={`${getFullUrlByLicense(
        license as keyof DomainLicense,
        locale
      )}/suggested`}
      customRef={ref}
      {...props}
      id="suggested"
    >
      <WithFallbackComponent
        predicat={data.length > 0}
        FallbackComponent={NoGameFallback}
      >
        <MotionHStack
          alignItems="stretch"
          spacing="1.8rem"
          css={ScrollWithoutScrollBar}
          paddingY="1rem"
          initial={'initial'}
          transition={{
            staggerChildren: 0.15,
            delayChildren: 0.5
          }}
          whileInView={'animate'}
          viewport={{ once: true, amount: 0.7 }}
          key={data.length}
        >
          {data.length > 0 ? (
            data.map((game, index) => (
              <SuggestedGame
                variants={rightToLeftChildrenElementAnimation}
                data-testid={`suggested-game-${index}`}
                // layout
                // layoutId={`Suggested-${game.id}`}
                key={game.id}
                game={game}
              />
            ))
          ) : (
            <Text as="p" textAlign="center" textStyle="p" fontWeight="bold">
              {t('common:NoGames')}
            </Text>
          )}
        </MotionHStack>
      </WithFallbackComponent>
    </SectionDescription>
  );
};

export default Suggested;
