// These urls don't need login
export const freeUrl = [
  '/[segment]',
  '/[segment]/category/[slug]',
  '/[segment]/email-confirmed',
  '/[segment]/forgot-password',
  '/[segment]/games/[id]/[slug]',
  '/[segment]/tournaments',
  '/[segment]/tournaments/[id]/[slug]',
  '/[segment]/info',
  '/[segment]/info/[slug]',
  '/[segment]/login',
  '/[segment]/logout',
  '/[segment]/most-popular',
  '/[segment]/password-reset',
  '/[segment]/register',
  '/[segment]/register/confirmation',
  '/[segment]/self-excluded',
  '/[segment]/suggested',
  '/[segment]/trendy',
  '/[segment]/news',
  '/[segment]/news/[slug]',
  '/[segment]/news/tags/[slug]',
  '/[segment]/terms-of-use',
  '/[segment]/privacy',
  '/[segment]/cookies-policy',
  '/dice/virtual-sports',
  '/404',
  '/banned',
  '/blacklist',
  '/cookies-policy',
  '/email-confirmed',
  '/error/epis',
  '/error-register',
  '/general-conditions',
  '/halls',
  '/legal-notices',
  '/register',
  '/responsible-gaming',
  '/sports',
  '/[segment]/info',
  '/[segment]/info/[slug]',
  '/[segment]/category/[slug]',
  '/halls',
  '/sports-pmu/[slug]',
  '/underage',
  '/sports-euro-2024',
  // altenar app
  '/_altenar/forgot-password',
  '/_altenar/register',
  '/account-created',
  '/[segment]/section/[slug]',
  '/sports-predictor',
  '/sports-golden-scores-euro2024',
  '/library',
  '/sentry-example-page'
];

// these urls need to be redirected to login page (when offline)
export const onlineUrl = [
  '/me/account/[tab]',
  '/me/settings/[tab]',
  '/me/wallet/[tab]',
  '/me/message' // todo?
];

const onlyNotLoggedUrls = [
  '[lang][licensePath]/forgot-password',
  '[lang][licensePath]/_altenar/forgot-password',
  '[lang][licensePath]/account-created'
];

export const isOnlyNotLoggedUrls = (
  url: string,
  lang?: string,
  licensePath?: string
): boolean => {
  const exist = onlyNotLoggedUrls.find((notLoggedUrl) => {
    notLoggedUrl = notLoggedUrl.replace('[lang]', lang ? `/${lang}` : '');
    notLoggedUrl = notLoggedUrl.replace(
      '[licensePath]',
      licensePath ? `${licensePath}` : ''
    );
    return notLoggedUrl === url;
  });
  return typeof exist !== 'undefined';
};
