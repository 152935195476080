import { useAuth } from '@/context/Auth';
import { useWallet } from '@/context/Wallet';
import { Button, ButtonProps, useToast } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { HTTP } from '../..';
import { Transaction } from './History';
import { useAppSettings } from '@/context/AppSettings';

type CancelCashoutButtonProps = ButtonProps & {
  cashout: Transaction;
  buttonTitle: string;
  callback?: () => void;
};

const CancelCashoutButton = ({
  cashout,
  buttonTitle,
  callback = () => null,
  ...props
}: CancelCashoutButtonProps) => {
  const appSettings = useAppSettings();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { isLogged } = useAuth();
  const { t } = useTranslation();
  const { getBalance } = useWallet();

  const confirmCancel = async () => {
    if (!cashout || !isLogged) return;

    setIsLoading(true);
    try {
      await HTTP.put(`/payout_requests/${cashout.id}/cancel?confirm=true`, {
        note: `from ${appSettings?.name ?? 'ge-oria'}`
      });
      toast({
        status: 'success',
        position: 'top',
        title: t('wallet:gamingHallCashoutCancelled')
      });

      getBalance?.();

      callback?.();
    } catch (error: any) {
      toast({
        status: 'error',
        position: 'top',
        title: t('common:somethingWrongHappenned')
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      backgroundColor="buttonPrimary"
      onClick={confirmCancel}
      isLoading={isLoading}
      marginTop="1rem"
      data-testid="gh-cashout-cancel-confirm"
      {...props}
    >
      {buttonTitle}
    </Button>
  );
};

export default CancelCashoutButton;
