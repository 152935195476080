import { toRem } from '@/helpers/toRem';
import { useCurrentHost } from '@/hooks/useCurrentHost';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  HTMLChakraProps,
  Link,
  ListItem,
  UnorderedList
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import {
  ContextualNavItem,
  activeDashStyle,
  isActive
} from './ContextualLinks';
import { useAuth } from '@/context/Auth';
import {
  DomainHUBLicense,
  getFullUrlByLicense,
  getHubHomeLinkByLicense,
  getLicenseByHost,
  isLicenseEnable
} from '@/utils/multiDomains';
import { useAppSettings } from '@/context/AppSettings';

const ContextualMainMenuItem = ({
  data,
  currentIdx
}: {
  data: ContextualNavItem[];
  currentIdx: number;
}) => {
  const currentHost = useCurrentHost();
  const currentLicense = getLicenseByHost(currentHost);
  const { t, lang: locale } = useTranslation();
  const { isLogged } = useAuth();
  const appSettings = useAppSettings();

  const mainMenuButton = (nav: ContextualNavItem) => {
    const style: HTMLChakraProps<any> = {
      textAlign: 'left',
      transition: 'all .3s ease',
      fontSize: toRem(24),
      fontWeight: '600',
      position: 'relative',
      _hover: {
        _after: {
          ...activeDashStyle('column'),
          opacity: 1
        }
      },
      _after: {
        ...activeDashStyle('column'),
        opacity: isActive(nav.license, currentHost) ? 1 : 0
      }
    };
    return isLicenseEnable(nav.license) && currentLicense !== 'MAIN' ? (
      <Box {...style}>{t(nav['translationKey'])}</Box>
    ) : (
      <Link
        {...style}
        href={
          currentLicense === 'MAIN'
            ? getHubHomeLinkByLicense({
                license: nav.license as keyof DomainHUBLicense,
                appSettingsFeatures: appSettings.features,
                locale
              })
            : getFullUrlByLicense(nav.license, locale, false, true, false)
        }
      >
        {t(nav['translationKey'])}
      </Link>
    );
  };

  return (
    <Accordion
      variant="unstyled"
      defaultIndex={[currentIdx]}
      data-testid={'contextual-main-menu-item'}
    >
      {data?.map((nav) => (
        <AccordionItem key={nav.translationKey}>
          <Heading
            as="h2"
            color={
              isActive(nav.license, currentHost)
                ? '#fff'
                : 'rgba(255,255,255,.6)'
            }
            position="relative"
            marginLeft={0}
            marginBottom={toRem(16)}
            fontSize={toRem(24)}
            transition="all .3s ease"
            fontWeight={'600'}
            mb=".5rem"
            _hover={{
              color: '#fff'
            }}
          >
            <AccordionButton>{mainMenuButton(nav)}</AccordionButton>
          </Heading>

          {nav.itemMenu?.length &&
          isLicenseEnable(nav.license) &&
          currentLicense !== 'MAIN' ? (
            <AccordionPanel pb={4}>
              <UnorderedList m="0">
                {nav.itemMenu.map((item, index) => {
                  return !item.needAuth || item.needAuth === isLogged ? (
                    <ListItem key={index} fontSize="1.2rem">
                      <Link
                        href={
                          item.needSegment
                            ? getFullUrlByLicense(nav.license, locale) +
                              item.links
                            : getFullUrlByLicense(
                                nav.license,
                                locale,
                                false,
                                true,
                                false
                              ) + item.links
                        }
                      >
                        {t(item.translationKey)}
                      </Link>
                    </ListItem>
                  ) : null;
                })}
              </UnorderedList>
            </AccordionPanel>
          ) : null}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default ContextualMainMenuItem;
