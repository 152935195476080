import SmarticoScript from '@/components/Smartico/SmarticoScript';
import { Box, Button, Image, Text } from '@chakra-ui/react';
import { LightCloseIcon } from '@/theme/Icons';
import { MotionBox } from '@/components/Motion';
import { useEffect, useRef, useState } from 'react';
import { useNotification } from '@/context/Notification';
import { useAuth } from '@/context/Auth';
import { useExtraProps } from '@/pages/_app';
import useTranslation from 'next-translate/useTranslation';
import { useAppSettings } from '@/context/AppSettings';

const SmarticoSpinWheel = () => {
  const { features, appName } = useAppSettings();

  const smarticoConfig = features.smartico || {};

  const [isActive, setActive] = useState(false);
  const [notificationListHeight, setNotificationListHeight] = useState(0);
  const [mobileBottomMenuHeight, setMobileBottomMenuHeight] = useState(0);
  const { notifToShow } = useNotification();
  const spinWheelRef = useRef<any>();

  const { isLogged, userData } = useAuth();
  const canPlay = !userData?.permission?.play ?? false;

  const { license } = useExtraProps();

  const { t } = useTranslation();

  // Get general notification list bar height to adapt spin wheel position fixed from top
  useEffect(() => {
    setNotificationListHeight(
      document?.querySelector?.('.general-notification-list')?.clientHeight || 0
    );
  }, [notifToShow]);

  // Get mobile bottom menu height to adapt spin wheel position fixed from bottom
  useEffect(() => {
    setMobileBottomMenuHeight(
      document?.querySelector?.('.mobile-bottom-menu')?.clientHeight || 0
    );
  }, [isLogged]);

  // If user already played, don't show the spin wheel for 1 day
  useEffect(() => {
    const hasPlayedToSpinWheel = JSON.parse(
      window.localStorage.getItem('hasPlayedToSpinWheel') || '{}'
    );

    if (hasPlayedToSpinWheel && hasPlayedToSpinWheel.date) {
      const hasPlayedToSpinWheelDate = new Date(hasPlayedToSpinWheel.date);
      const canPlayDate = new Date(hasPlayedToSpinWheel.date).setDate(
        hasPlayedToSpinWheelDate.getDate() + 1
      );
      if (canPlayDate <= Date.now()) {
        setActive(true);
      }
    } else {
      setActive(true);
    }
  }, []);

  if (license !== 'B') return null;

  // 104 (6.5rem) === the overflow of the spin wheel image
  // 1000 === estimated max width of the container
  const totalSpinWheelWidth =
    spinWheelRef && spinWheelRef.current
      ? spinWheelRef.current.clientWidth + 104
      : 1000;

  return isLogged && isActive && !canPlay && smarticoConfig.enabled ? (
    <>
      <SmarticoScript />
      {!smarticoConfig.spinWheel.hidden ? (
        <>
          <iframe
            id="match-x-game"
            style={{ width: '100%', height: '0', display: 'none' }}
          ></iframe>
          <MotionBox
            data-testid={'spin-wheel'}
            ref={spinWheelRef}
            animate={{
              right: isActive ? [0, 0] : [0, -totalSpinWheelWidth]
            }}
            transition={{ duration: 0.5 }}
            initial={{
              right: 0
            }}
            bottom={[
              mobileBottomMenuHeight + 24,
              mobileBottomMenuHeight + 24,
              'initial',
              'initial'
            ]}
            cursor={'pointer'}
            position={'fixed'}
            zIndex={'popover'}
            top={['initial', 'initial', notificationListHeight + 104]}
            display={'flex'}
            flexDirection={'column'}
            width={['100%', '100%', 'auto']}
          >
            <Box display={'flex'} justifyContent={'end'}>
              <Button
                bg={'spinWheel.bg'}
                borderRadius={'0.25rem'}
                marginRight={'1.5rem'}
                width={'2rem'}
                height={'2rem'}
                minWidth={'auto'}
                padding={0}
                onClick={() => {
                  setActive(!isActive);
                }}
              >
                <LightCloseIcon width={'1.5rem'} height={'1.5rem'} />
              </Button>
            </Box>
            <Box
              display={'flex'}
              position={'relative'}
              alignItems={'center'}
              height={['5.75rem', '5.75rem', '9.5rem']}
              marginTop={'1rem'}
              paddingLeft={['0', '0', '6.5rem']}
              bg={'spinWheel.bg'}
              onClick={() => {
                window._smartico?.miniGame(smarticoConfig.spinWheel.id);
                window.localStorage.setItem(
                  'hasPlayedToSpinWheel',
                  JSON.stringify({
                    date: Date.now()
                  })
                );
                setActive(false);
              }}
            >
              <Image
                alt={t('smartico:spinTheWheel')}
                src={`/${appName}/spin-wheel.png`}
                width={['8.25rem', '8.25rem', '13.08rem']}
                height={['6.87rem', '6.87rem', '10.89rem']}
                position={['initial', 'initial', 'absolute']}
                left={['initial', 'initial', '-6.5rem']}
                top={['initial', 'initial', '50%']}
                transform={['initial', 'initial', 'translate(0, -50%)']}
                marginLeft={['1.5rem', '1.5rem', '0']}
                marginTop={['-2rem', '-2rem', '0']}
              />
              <Box ml={'1.5rem'} mr={'2rem'}>
                <Text
                  fontSize={['1.125rem', '1.125rem', '1.5rem']}
                  fontWeight={500}
                  lineHeight={['1.25rem', '1.25rem', '1.875rem']}
                >
                  {t('smartico:spinTheWheel')}
                </Text>
                <Text
                  fontSize={['1rem', '1rem', '1.125rem']}
                  lineHeight={['1.25rem', '1.25rem', '1.625rem']}
                >
                  {t('smartico:winPointsFor')}
                </Text>
              </Box>
            </Box>
          </MotionBox>
        </>
      ) : null}
    </>
  ) : null;
};

export default SmarticoSpinWheel;
